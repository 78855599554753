.tab-content {
    background-color: $white;
    padding: 30px;
}

.css-1s2u09g-control, .css-1pahdxg-control{   
    background-color: $input-solid-bg !important;
    border-color: $input-solid-bg !important;
}


.css-1s2u09g-control:active,
.css-1s2u09g-control:hover,
.css-1s2u09g-control:focus,
.css-1s2u09g-control.focus,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus,
.css-1pahdxg-control.focus {
  background-color: $input-solid-bg-focus !important;
  border-color: $input-solid-bg-focus !important;
  color: $input-solid-color;
  transition: $transition-input;
  box-shadow: none;
}

.select-icon i, .css-qc6sy-singleValue, .css-6j8wv5-Input {
    color: $input-solid-color !important;
}

.colored-toast.swal2-icon-success {
  background-color: $amarillo !important;
  
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: $azul-oscuro !important;
}

.colored-toast .swal2-close {
  color: $white;
}

.colored-toast .swal2-html-container {
  color: $white;
}

@include media-breakpoint-up(lg) {

  .header-fixed.toolbar-fixed .wrapper {
    padding-top: calc(#{get($header-config, fixed, height, desktop)} );
  }
}


/*
  Custom Styles for React-Data-Datable in Si Cuentanos Project
*/
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable div{
  white-space: pre-line !important;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow{
  border-bottom: none !important;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rdt_Table .rdt_TableBody .rdt_TableRow{
  border-bottom: none !important;
}
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

input.filter {
  padding: 6px 10px;
}
button.btn-filter {
  min-width: 100px;
  margin-left: 5px;
}

/*
  Custom Styles for Loader 
*/
#splash-screen.splash-screen{
  background-color: rgb(242 243 248 / 30%);
}

@keyframes scale-div {
  to {
    transform:perspective(100px) translate(-50%, -50%) translateZ(5px);
  }
}

.scale {
  position: absolute;
  left: 50%;
  transform:perspective(100px) translate(-50%, -50%);
  transform-style:preserve-3d;
  animation: scale-div 0.5s infinite linear alternate;
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 9px 15px;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.menu.menu-sub.menu-sub-dropdown.menu-user-login{
  width: fit-content;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-container {
  text-align: center;
}

.tooltip-negro .tooltip-inner
{
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.tooltip-negro .tooltip-arrow::before{
  border-right-color: rgba(0, 0, 0, 0.5);
}
.tooltip-negro.bs-tooltip-end,
.tooltip-negro.bs-tooltip-auto[data-popper-placement^=right]{
  padding: 0 0.43rem;
}